import * as moment from 'moment';

export const titleStatisticWebPage = "CMS Datashare";

export const DATE_TIME_REACT_FORMAT = 'dd/MM/yyyy HH:mm';
export const DATE_TIME_REACT_WITHOUT_HOUR_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_REACT_TYPE_ONE_WITHOUT_HOUR_FORMAT = 'DD/MM/YYYY';
export const ID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const timeStep = 15;
export const TOKEN_KEY = 'auth_Datashare';
export const CRAWLER_SLUGNAME = 'Datashare';
export const acceptImage = 'image/png,image/jpg,image/jpeg,image/gif';
export const acceptDoc = 'image/png,image/jpg,image/jpeg,image/gif,application/pdf,.doc, .docx';
export const acceptZip = 'application/zip';
export const acceptVideo = 'video/mp4,video/x-m4v,video/*';
export const status200 = 200;
export const status201 = 201;
export const status400 = 400;
export const levelMessage = {
  error: 'error',
  info: 'info',
  warning: 'warning',
  success: 'success'
};

export const postStatus = {
  all: '',
  release: 'release',
  draft: 'draft',
  pending: 'pending',
  refuse: 'refuse'
};

export const Gender = {
  male: 'Male',
  female: 'Female',
};


export const objectStatusPost = [
  { label: 'Tất cả', value: postStatus.all },
  { label: 'Xuất bản', value: postStatus.release },
  { label: 'Bản nháp', value: postStatus.draft },
  { label: 'Chờ duyệt', value: postStatus.pending },
  { label: 'Từ chối', value: postStatus.refuse }
];

export const dataTypeCategory = {
  all: '',
  information: 'information',
  video: 'video',
  image: 'image',
  introduce: 'introduce',
  pdf: "pdf",
  person: "person",
  live: "live",
  googleform: "googleform"
};

export const objectDataTypeCategory = [
  { label: 'Danh sách tin tức', value: dataTypeCategory.information },
  { label: 'Danh sách video', value: dataTypeCategory.video },
  { label: 'Danh sách ảnh', value: dataTypeCategory.image },
  { label: 'Giới thiệu', value: dataTypeCategory.introduce },
  { label: 'PDF', value: dataTypeCategory.pdf },
  { label: 'Nhân sự', value: dataTypeCategory.person },
  { label: 'Live', value: dataTypeCategory.live },
  { label: 'Khảo sát', value: dataTypeCategory.googleform },
];

export const dataTypeVideo = {
  none: 'none',
  all: "all",
  program: "program",
  tvc: "tvc",
  qc: "qc",
};
export const objectDataTypeVideo = [
  { label: 'Không phải video', value: dataTypeVideo.none },
  { label: 'Video', value: dataTypeVideo.all },
  { label: 'Video Chương trình', value: dataTypeVideo.program },
  { label: 'Video Doanh nghiệp', value: dataTypeVideo.tvc },
  { label: 'Video Quảng Cáo', value: dataTypeVideo.qc },
];

export const objectShowNumber = [
  { text: '20', value: 20 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
];

export const reportPost = {
  ByStatus: 'ByStatus',
  ByCreatedDate: 'ByCreatedDate',
  ByPublicDate: 'ByPublicDate',
  DataType: 'DataType',
  Author: 'Author',
  Category: 'Category'
};
export const reportAdvertisingAnalysis = {
  ByImageThumb: 'ByImageThumb',
  ByDate: 'ByDate',
  ByCategoryId: 'ByCategoryId',
};
export const objectReportAdvertisingAnalysis = [
  { text: 'Link quảng cáo', value: reportAdvertisingAnalysis.ByImageThumb },
  { text: 'Ngày ', value: reportAdvertisingAnalysis.ByDate },
  { text: 'Danh mục', value: reportAdvertisingAnalysis.ByCategoryId },
];


export const objectReportPost = [
  { text: 'Trạng thái', value: reportPost.ByStatus },
  { text: 'Ngày tạo', value: reportPost.ByCreatedDate },
  { text: 'Ngày xuất bản', value: reportPost.ByPublicDate },
  { text: 'Loại', value: reportPost.DataType },
  { text: 'Người tạo', value: reportPost.Author },
  { text: 'Danh mục', value: reportPost.Category },
];
export const objectStatusCrawl = [
  { text: 'Tất cả', value: '' },
  { text: 'Xuất bản', value: 'active' }
];

export const objectTypeInput = [
  { text: 'Text', value: 'text' },
  { text: 'Html', value: 'html' }
];

export const role = {
  Admin: 'admin',
  Manager: 'manager',
  Publisher: 'publisher',
  Editor: 'editor',
  Custommer: 'custommer'
};
export const objectRoles = [
  { label: 'Admin', value: role.Admin },
  { label: 'Chủ sở hữu', value: role.Manager },
  { label: 'Xuất bản', value: role.Publisher },
  { label: 'Biên tập', value: role.Editor },
  { label: 'Khách', value: role.Custommer }
];

export const positionBanner = [
  { label: 'Bên trái', value: 'left' },
  { label: 'Bên phải', value: 'right' },
  { label: 'Trên cùng', value: 'top' },
  { label: 'Phía dưới', value: 'bottom' }
];
export const typeBanner = [
  { value: '1', label: 'Banner ngang' },
  { value: '0', label: 'Banner dọc' }
];

export const typeError = {
  UserName: 'UserName',
  Email: 'Email',
  Code: 'Code'
};

export const messageError = {
  emptyMessage: 'Trường dữ liệu không được để trống',
  emailMessage: 'Địa chỉ email không chính xác',
  minLength6Message: 'Độ dài tối thiểu 6 ký tự',
  maxLength30Message: 'Độ dài không vượt quá 30 ký tự',
  likePassword: 'Mật khẩu không trùng nhau'
};
export const messageToast = {
  messageToastReportExcelSuccess: 'Xuất dữ liệu thành công',
  messageToastReportExcelError: 'Xuất dữ liệu không thành công',
  messageToastDeleteSuccess: 'Xóa dữ liệu thành công',
  messageToastCreateSuccess: 'Thêm mới dữ liệu thành công',
  messageToastUpdateSuccess: 'Cập nhật dữ liệu thành công',
  messageToastDeleteError: 'Xóa dữ liệu không thành công',
  messageToastCreateError: 'Thêm mới dữ liệu không thành công',
  messageToastUpdateError: 'Cập nhật dữ liệu không thành công',
  messageToastTryCatch: 'Gặp lỗi khi thực hiện thao tác, vui lòng thử lại!',
  messageToastChangePassSuccess: 'Đổi mật khẩu thành công',
  messageToastChooseDateError: 'Mời chọn ngày',
  messageToastRecoverPassSuccess: 'Mật khẩu đã được cập nhât, xin mời đăng nhập lại'
};

export const dataTypeComponent = [
  { label: 'html', value: 'html' },
  { label: 'ảnh', value: 'image' },
  { label: 'video', value: 'video' },
  { label: 'text', value: 'text' },
  { label: 'nâng cao', value: 'model' }
];

export const styleSelectError = {
  control: (base) => ({
    ...base,
    border: '1px solid #dc3545',
    boxShadow: 'none'
  })
};

export const styleSelectDefault = {
  control: (base) => ({
    ...base
  })
};

export const rangeDate = {
  Today: [moment().toDate(), moment().toDate()],
  Yesterday: [
    moment().subtract(1, 'days').toDate(),
    moment().subtract(1, 'days').toDate()
  ],
  'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
  'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
  'This Month': [
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate()
  ],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month').toDate(),
    moment().subtract(1, 'month').endOf('month').toDate()
  ]
};
export const validInput = (type, value, isCheckSpace = false) => {
  if (
    type.toLowerCase() === 'text' ||
    type.toLowerCase() === 'option' ||
    type.toLowerCase() === 'tagselect' ||
    type.toLowerCase() === 'file'
  ) {
    if (isCheckSpace) {
      if (value.length === 0 || value.includes(' ')) {
        return false;
      }
    } else {
      if (value == null || value.length === 0) {
        return false;
      }
    }
    return true;
    // let regexp = /([^\s])/;
    // let checkingResult = regexp.exec(text);
    // return checkingResult !== null ? true : false;
  }
  if (type.toLowerCase() === 'number') {
    if (value == null || value.length === 0 || value === 0) {
      return false;
    }
    return true;
  }
  if (type.toLowerCase() === 'password') {
    let regexp = /^(?=.{8,})/;
    // let regexp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let checkingResult = regexp.exec(value);
    return checkingResult !== null ? true : false;
  }
  if (type.toLowerCase() === 'notspace') {
    let regexp = /^(\w+\S+)$/;
    let checkingResult = regexp.exec(value);
    return checkingResult !== null ? true : false;
  }

  if (type.toLowerCase() === 'phone') {
    let regexp = /^\+?\d+$/;
    try {
      if (regexp.test(value)) {
        const phoneNumber = parsePhoneNumberFromString(
          `Phone: ${parseInt(value)}`,
          'VN',
        );
        if (phoneNumber !== undefined && phoneNumber.isValid()) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    } catch (e) {
      console.error('e', e);
    }

    // let regexp = /^([0|\+[0-9]{1,5})?([0-9]{10,12})$/;
    // let checkingResult = regexp.exec(text);
    // return checkingResult !== null ? true : false;
  }
  if (type.toLowerCase() === 'email') {
    var pattern =
      /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/;
    return pattern.test(value);
  }
  return true;
};
